import React, { useState, memo } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import Layout from "../components/layout";
import Seo from "../components/seo";
import withPreview from "../utility/with-preview";
import useInitDataLayer from "../utility/useInitDataLayer";
import { useHandleRecaptchaBadge } from "../utility/useHandleRecaptchaBadge";

const Section = memo(({ section, activeTab }) => {
  return (
    <div className="w-100">
      {section.content.paragraphs.map((paragraph, j) => (
        <React.Fragment key={j}>
          <span className="scroll-anchor" id={`subguidelines_${activeTab + 1}${j + 1}`}></span>
          {paragraph.content.paragraphText.value.includes("/documents/") ? (
            <div className="documentazione__extension">
              <span
                dangerouslySetInnerHTML={{ __html: paragraph.content.paragraphText.value }}
              ></span>
              <h3>{paragraph.value}</h3>
            </div>
          ) : (
            <>
              <h3 className="subguideline_title">{paragraph.value}</h3>
              <div
                className="subguideline"
                dangerouslySetInnerHTML={{ __html: paragraph.content.paragraphText.value }}
              ></div>
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
});

const TemplatePaginaPrivacy = ({ data: { page }, location }) => {
  const { pageTitle, htmlTitle, heroImage, sections } = page;
  const [activeTab, setActiveTab] = useState(0);
  useInitDataLayer(location);
  useHandleRecaptchaBadge(false);

  return (
    <Layout location={location}>
      <Seo
        title={htmlTitle.value || pageTitle.value}
        ogImage={htmlTitle.content?.ogImage}
        description={htmlTitle.content?.metaDescription?.value}
        robots={htmlTitle.content?.metaRobots?.value}
        path="/privacy/"
      />
      <div className="privacy__hero">
        <GatsbyImage
          image={getImage(heroImage.node)}
          alt={heroImage.value.description || ""}
          className="privacy__hero-img"
        />
        <div className="wrapper-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1
                  className="privacy__hero-title"
                  dangerouslySetInnerHTML={{
                    __html: heroImage.content?.heroTitle?.value || pageTitle.value,
                  }}
                ></h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sections ? (
        <div className="privacy wrapper-container">
          <div className="container-fluid">
            <div className="row pt-2 px-3">
              {sections.map((section, i) => (
                <button
                  key={i}
                  className={classNames(
                    `col-12 col-lg-${Math.floor(12 / sections.length)} subtablinks`,
                    {
                      active: activeTab === i,
                    }
                  )}
                  onClick={() => setActiveTab(i)}
                >
                  {section.value}
                </button>
              ))}
            </div>
            <div className="row position-relative pt-5 pb-5">
              <div className="col-md-3 px-2 d-none d-lg-block banner-Guidelines-menuitem">
                <div className="banner-Guidelines-placeholder"></div>
                <div className="menu-fixed no-nav-mobile">
                  <ul className="nj-list-group">
                    {sections[activeTab].content.paragraphs.map((paragraph, j) => (
                      <a
                        href={`#subguidelines_${activeTab + 1}${j + 1}`}
                        className="scroll"
                        key={j}
                      >
                        {paragraph.value}
                      </a>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 d-flex banner-Guidelines-guidelines-text">
                <Section section={sections[activeTab]} activeTab={activeTab} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default withPreview(TemplatePaginaPrivacy, {
  subField: "page",
  fixed: true,
});

export const query = graphql`
  query ($id: String) {
    page: liferayJskLayoutPaginaPrivacy(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
      }
      pageTitle {
        value
      }
      friendlyUrl {
        value
      }
      heroImage {
        value {
          description
        }
        node {
          gatsbyImageData(width: 1440, layout: FULL_WIDTH)
        }
        content {
          heroTitle {
            value
          }
        }
      }
      htmlTitle {
        value
        content {
          metaDescription {
            value
          }
          metaRobots {
            value
          }
          ogImage {
            node {
              resize(width: 1200) {
                src
                width
                height
              }
            }
          }
        }
      }
      sections {
        value
        content {
          paragraphs {
            value
            content {
              paragraphText {
                value
              }
            }
          }
        }
      }
    }
  }
`;
