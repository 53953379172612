import { useEffect } from "react";

export const useHandleRecaptchaBadge = (fasce) => {
  /* Visualizzazione badge recaptcha solo se in pagina è pesente componente FlussoSwitchIn, FormContatti, NewGenericLanding, WidgetContatti, FormAutolettura */
  useEffect(() => {
    if (
      fasce &&
      fasce?.find((fascia) =>
        [
          "LiferayJskFlussoSwitchIn",
          "LiferayJskFormContatti",
          "LiferayNewGenericLanding",
          "LiferayWidgetContatti",
          "LiferayJskFormAutolettura",
        ].includes(fascia.value.__typename)
      )
    ) {
      document.body.classList.add("show-grecaptcha");
    }
    return () => document.body.classList.remove("show-grecaptcha");
  }, [fasce]);
};
