import { useEffect } from "react";
import { dataLayerPush, dataLayerStr2Obj } from "./dataLayerUtils";

const useInitDataLayer = (location, impressionDetail) => {
  useEffect(() => {
    const obj = {
      userLoginState: "no-logged",
      userId: "",
      pageTag: "",
      pageName:
        location?.pathname?.split("/")?.[
          location?.pathname?.split("/")?.length - (location?.pathname.endsWith("/") ? 2 : 1)
        ],
    };

    if (impressionDetail?.value) {
      obj.ecommerce = dataLayerStr2Obj(impressionDetail.value);
    }
    dataLayerPush(obj);
  }, [location?.pathname, impressionDetail]);
};

export default useInitDataLayer;
